.grid-container {
  height: calc(100% - 70px);
  min-height: 100vh;
  margin: 0 0 70px;
  transition: all 500ms;
  left: 0;

  &.toggleClosed {
    width: calc(100% - 75px);
    left: 75px;
  }
}

.actioncenter {
  margin: 0 auto;
  position: relative;
  padding: 10px 0 20px;
  width: 96%;

  &[data-active-module='plan'] {
    padding: 0;
    width: 100%;
  }
}

@media screen and (min-width: 900px) {
  .grid-container {
    position: relative;
    left: 200px;
    width: calc(100% - 200px);
    margin: 0;
  }
}
