//======================================
// Mini Reset
//--------------------------------------
*,
*:after,
*:before {
  box-sizing: border-box;
}

html {
	font-size: 100%;
	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
	line-height: 1.15;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li {
	margin: 0;
	padding: 0;
}

article,
aside,
footer,
header,
main,
nav,
section {
	display: block;
}

a {
	text-decoration: none;
}

ul {
	list-style: none;
}

img {
  border: 0;
  border-style: none;
	display: inline-block;
	height: auto;
}

embed,
iframe,
img,
object,
video {
	max-width: 100%;
}

input,
textarea,
select,
option,
button {
	border: 0;
	border-radius: 0;
  outline: none;

  &:hover {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  &:active {
    outline: none;
  }
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

button,
select {
  text-transform: none;
}

select {
  -webkit-appearance: none;
}

button {
  background: none;
  padding: 0;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}