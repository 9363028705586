.popup {
    border-radius: $r-sm;
    background: $bg-light;
    max-height: calc(100vh - 100px);
    max-width: 400px;
    overflow-y: auto;
    padding: 40px 12px 16px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 96%;
    z-index: 101;
    // fix for iOS scrolling and display issue (https://stackoverflow.com/questions/9807620/ipad-safari-scrolling-causes-html-elements-to-disappear-and-reappear-with-a-dela)
    *:not(html):not(svg):not(label):not(span) {
        transform: translate(0, 0);
        -webkit-transform: translate3d(0, 0, 0);
    }
    // -------
    &--static {
        // popups that stay in the dom
        display: none;

        &.visible {
            display: block;
        }
    }

    &--filter {
        button {
            padding: 14px 16px;
            width: 100%;
        }
    }

    &__header {
        font-weight: $normal;
        margin: 0 0 10px;
    }

    .dropdown,
    .input-field {
        margin-top: 8px;
    }

    .closePopupBtn {
        width: auto;
        position: absolute;
        top: 8px;
        right: 8px;
    }

   
}

#formPopup.popup {
    max-width: 100%;
    width: auto;
  }

.popup {
    &.warning {
        background: $bg-warning;

        p {
            color: $font-dark;
            font-weight: $normal;
            text-align: center;
            padding-bottom: 10px;
        }
    }

    &.error {
        background: $bg-error;

        p {
            color: $font-light;
            font-weight: $normal;
        }
    }

    &.disabled {
        * {
            pointer-events: none;
        }
    }
}

.mobileActive {
  .popup {
    max-height: 360px;

    @media screen and (min-height: 560px) {
      max-height: 400px;
    }
    @media screen and (min-height: 800px) {
      max-height: 600px;
    }
  }
}

.password-error {
    color: red;
    border-color: red;
}

.successfulSavePopup, .failSavePopup {
  text-align: center;

  svg {
    color: $secondary;
    margin: 0 0 20px;
    width: 100px;
  }
  
  p {
    font-size: 22px;
    font-weight: $normal;
  }
}
.failSavePopup > svg {
  color: $red;
}
.pendingSavePopup {
  text-align: center;
  overflow: hidden;
  padding-top: 0px;
  .spinner {
    margin-bottom: 20px;
    .spinner__message {
      display: block;
    }
  }

  svg {
    color: $secondary;
    margin: 0 0 20px;
    width: 100px;
  }

  p {
    font-size: 22px;
    font-weight: $normal;
  }
}
// container for PDF WebViewer
#formPopup {
  max-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  z-index: 9999999999;
//  PDF WebViewer
#viewer { 
  max-height: 100%;
  height: auto;
  max-width: 100%;
  overflow-y: auto;
}
}

.popup__background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}