/* The main scrollbar */
::-webkit-scrollbar {
    scrollbar-width: auto; 
    //width: 15px;  
}

/* The background of the draggable scrolling handle */
::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* On hover, make the scrolling handle lighter */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

/* The scrollbar track */
::-webkit-scrollbar-track {
    background: #f1f1f1;
}

body {
    -webkit-overflow-scrolling: touch !important;
    background: $bg-light;
    color: $font-dark;
    overflow: auto;
}

textarea {
  resize: none;
}

svg {
  .fill-path {
    fill: currentColor;
  }
}

.freezeScroll {
    overflow: hidden;
}

.overlay {
  background: $primary;
  display: none;
  height: 100%;
  opacity: 0.9;
  position: fixed;
  top: 0;
  width: 100%;

  &.visible {
    display: block;
  }

  &.modal {
    display: block;
    z-index: 100;
    background: transparent;
  }
}

.error {
  color: $font-error;
}
.warning {
  color: $font-warning;
}
.customLink {
  cursor: pointer;
}

.ellipsis {
  border-radius: $r-sm;
  background: $gray;
  font-weight: $bold;
  padding: 0 5px;
  margin: 0 0 0 4px;
}

.landingMessage {
  text-align: center;
  margin-top: 20px;
}

.disabledModule {
  /*display:none;*/
  opacity: 0.5;
  pointer-events: none;
}

.bold {
  font-weight: $normal;
}

.filteredByData {
  border-bottom: 2px solid $secondary;
  display: flex;
  flex-flow: row wrap;
  padding: 0 0 5px 0;
  position: relative;
  margin-top : 15px; 
  margin-bottom : 10px;

  &::before {
    content: 'Filtered By:';
    font-size: 12px;
    font-weight: $normal;
    position: absolute;
    top: -15px;
  }

  p {
    color: $primary;
    font-weight: $normal;
    padding: 0 6px 0 0;
  }

  span {
    color: black;
  }
}

.disabled {
  color: rgba(0, 0, 0, 0.5) !important;
  pointer-events: none;
}

.noPointerEvents {
  pointer-events: none;
}

.heading {
  color: $primary;
  font-weight: $normal;
}
